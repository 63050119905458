import state from "./state";

export function getUserDetails() {
  return state.user;
}
export function getRefData() {
  return state.refData;
}
export function getIsLoadingArray() {
  return state.isLoadingArray;
}
