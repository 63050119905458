import Vue from "vue";
import VueRouter from "vue-router";
import useJwt from "@/auth/jwt/useJwt";
import VueI18n from "vue-i18n";
// const titleLocales = { ru, en, es };

function checkToken() {
  const isUserLoggedIn = localStorage.getItem(
    useJwt.jwtConfig.storageTokenKeyName
  );
  return !!isUserLoggedIn;
}

const LANGUAGES_STRING = ":lang(en|de|fr|pt)?";
// eslint-disable-next-line consistent-return
async function protectedRoute(to, from, next) {
  next();
}

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  },
  routes: [
    {
      path: `/${LANGUAGES_STRING}`,
      name: "home",
      component: () => import("@/views/home/Home.vue"),
      children: [
        {
          path: "",
          name: "home",
          beforeEnter: protectedRoute,
          component: () => import("@/views/home/Home.vue"),
          meta: {
            pageTitle: "home",
            breadcrumb: [
              {
                text: "home",
                active: true
              }
            ]
          }
        }
      ],
      meta: {
        pageTitle: "home",
        breadcrumb: [
          {
            text: "home",
            active: true
          }
        ]
      }
    },
    {
      path: `/${LANGUAGES_STRING}/aboutUs`,
      name: "aboutUs",
      component: () => import("@/views/aboutUs/aboutUs.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "/aboutUs",
      name: "aboutUs",
      component: () => import("@/views/aboutUs/aboutUs.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: `/${LANGUAGES_STRING}/termsOfService`,
      name: "termsOfService",
      component: () => import("@/views/aboutUs/TermsOfService.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "/termsOfService",
      name: "termsOfService",
      component: () => import("@/views/aboutUs/TermsOfService.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: `/${LANGUAGES_STRING}/legal`,
      name: "legal",
      component: () => import("@/views/aboutUs/Legal.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "/legal",
      name: "legal",
      component: () => import("@/views/aboutUs/Legal.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: `/${LANGUAGES_STRING}/help`,
      name: "help",
      component: () => import("@/views/aboutUs/Help.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "/help",
      name: "help",
      component: () => import("@/views/aboutUs/Help.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: `/${LANGUAGES_STRING}/privacyPolicy`,
      name: "privacyPolicy",
      component: () => import("@/views/aboutUs/PrivacyPolicy.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "/privacyPolicy",
      name: "privacyPolicy",
      component: () => import("@/views/aboutUs/PrivacyPolicy.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: `/${LANGUAGES_STRING}/support`,
      name: "support",
      component: () => import("@/views/aboutUs/support.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "/support",
      name: "support",
      component: () => import("@/views/aboutUs/support.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: `/${LANGUAGES_STRING}/error-404`,
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full"
      }
    },
    {
      path: "*",
      redirect: "error-404"
    }
  ]
});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
