export default function () {
  return {
    user: {},
    refData: {
      roles: [],
      userStatus: [],
      sysUserStatus: [],
      tradeTypes: [],
      userLevels: [],
      sysRoles: [],
      identificationTypes: [],
      verificationType: [],
      myAssignedOrderDisputeStatus: ["UNDER_REVIEW", "RESOLVED"],
      orderDisputeScope: [],
      orderDisputeStatus: [],
      verificationStatus: [],
      webUserStatus: []
    },
    isLoadingArray: []
  };
}
