import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";
import i18n from "@/libs/i18n/index";
import router from "@/router";
import store from "@/store";

export default class APIService {
  async api(
    { url, method },
    data,
    options = {
      show_loader: true,
      http_headers: {},
      api_version: undefined,
      isLoading: false
    }
  ) {
    let flag = false;
    try {
      if (options.isLoading) {
        let isLoadingArray = store.state.user.isLoadingArray;
        isLoadingArray.push(1);
        store.commit("user/setLoadingArray", isLoadingArray);
        flag = true;
      }

      const headers = {
        "Content-Type": "application/json",
        // Accept: "*/*"
        "Accept-Language": i18n.vm.locale
      };
      const token = localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName);
      if (token) {
        headers.authorization = `Bearer ${token}`;
      }
      const config = {
        method,
        url: process.env.VUE_APP_API + url,
        headers: {
          ...headers,
          ...options.http_headers
        }
      };
      if (method === "GET" && data) {
        config.params = data;
      } else {
        config.data = data;
      }
      const res = await axios(config);

      if (options.isLoading) {
        let isLoadingArray = store.state.user.isLoadingArray;
        isLoadingArray.pop();
        store.commit("user/setLoadingArray", isLoadingArray);
        flag = false;
      }
      if (
        res &&
        res.data &&
        res.data.result &&
        res.data.result.errors &&
        res.data.result.errors[0] &&
        res.data.result.errors.length &&
        res.data.result.errors[0].code === "553"
      ) {
        this.logout();
      } else if (
        res &&
        res.data &&
        res.data.result &&
        res.data.result.errors &&
        res.data.result.errors.length &&
        res.data.result.errors[0].message
      ) {
        return { error: { ...res.data.result.errors[0] } };
      }

      if (res && res.data) {
        return res.data;
      }
      return res;
      // return {
      //   error: {
      //     header: { status: "ERROR" },
      //     message: "Something went wrong"
      //   }
      // };
    } catch (err) {
      if (flag && options.isLoading) {
        let isLoadingArray = store.state.user.isLoadingArray;
        isLoadingArray.pop();
        store.commit("user/setLoadingArray", isLoadingArray);
        flag = false;
      }
      if (
        err.response &&
        err.response.data &&
        err.response.data.result &&
        err.response.data.result.errors &&
        err.response.data.result.errors.length &&
        err.response.data.result.errors[0].message
      ) {
        return { error: { ...err.response.data.result.errors[0] } };
      }
      if (err.response) {
        return err.response.data;
      }
      console.log(JSON.stringify(err));
      return {
        error: { header: { status: "ERROR" }, message: "Something went wrong" }
      };
    }
  }

  // eslint-disable-next-line class-methods-use-this
  logout() {
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
    localStorage.removeItem("userInfo");
    if (router.currentRoute.name !== "auth-login")
      router.push({ name: "auth-login" });
    return true;
  }
}
