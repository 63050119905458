import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import router from "./router";
import store from "./store";
import App from "./App.vue";
import i18n from "@/libs/i18n";
import acl from "@/utils/acl";
// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
// import VueCameraLib from "vue-camera-lib";
// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
// Vue.use(VueCameraLib);
// Composition API
Vue.use(VueCompositionAPI);
Vue.mixin({
  methods: acl,
  data() {
    return {};
  }
});

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
