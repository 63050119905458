import APIService from "@/libs/api/api";

export async function getProfileDetails(ctx) {
  ctx.commit("setUser");
}
// export async function fetchKycRefData({ commit }) {
//   const response = await new APIService().api(
//     { method: "GET", url: "kyc/refData/IN" },
//     {},
//     {}
//   );
//   if (response.result.errors) {
//     return;
//   }

//   commit("setRefData", response.refData);
// }
