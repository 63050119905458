<template>
  <div v-if="show" class="parent-loader">
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Loader",
  props: ["show"]
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.parent-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(54, 53, 53, 0.1);
  z-index: 9;
  .loading-logo {
    position: relative;
  }
  .loading {
    position: relative;
  }
}
</style>
